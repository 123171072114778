import ReactPlayer from "react-player";
import { Fragment, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { ParagraphComponent } from "@components/ParagraphComponent";
import { TitleH4Component } from "@components/TitleH4Component";
import { TitleH6Component } from "@components/TitleH6Component";
import Picture from "@ui/general/Picture";
import { useApi } from "@context/ApiContext";
import { url_image } from "@api/ProyectoApi";
import Style from "@scss/bannerStore.module.scss";
import StyleGeneral from "@scss/general.module.scss";
import useSWR from "swr";
import {
  getImagesByGalleryKey,
  getImageByKey,
} from "@integrations/prodesa/api";

const CACHE_COMPONENT = 1800;

const getData = (url) => {
  const data = Promise.all([
    getImagesByGalleryKey("telefono-llamanos", false, CACHE_COMPONENT),
    getImagesByGalleryKey("telefono-escribenos", false, CACHE_COMPONENT),
    getImagesByGalleryKey("email-contactenos", false, CACHE_COMPONENT),
    getImageByKey("logo-android", false, CACHE_COMPONENT),
    getImageByKey("logo-apple", false, CACHE_COMPONENT),
    getImageByKey("video-home-mobile", false, CACHE_COMPONENT),
    getImageByKey("mobile-video-imagen", false, CACHE_COMPONENT),
    getImageByKey("image-phone-home", false, CACHE_COMPONENT),
  ]).then(
    ([
      llamanos,
      escribenos,
      email,
      logoAndroid,
      logoApple,
      video,
      videoimagen,
      phone,
    ]) => {
      return {
        llamanos,
        escribenos,
        email,
        logoAndroid,
        logoApple,
        video,
        videoimagen,
        phone,
      };
    }
  );
  return data;
};

export default function Store() {
  const { data, error } = useSWR("/", getData);

  const { getTextoByKey, getTranslation } = useApi();

  const [playing, setPlaying] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(true);

  const handlePlay = () => {
    setPlaying(!playing);
  };

  const handleOnPlay = () => {
    setShowPlayButton(false);
  };

  if (error || !data) {
    return <></>;
  }

  const {
    llamanos,
    escribenos,
    email,
    logoAndroid,
    logoApple,
    video,
    videoimagen,
    phone,
  } = data;

  return (
    <section>
      <div className="container pb-3 pb-md-5 pt-3">
        <div className="row">
          <div className="col-lg-4 d-flex justify-content-center align-items-center">
            <div className="p-2">
              <TitleH4Component styleGroup="roboto text-center color-gris">
                {getTextoByKey("titulo-canales-atencion")?.valor}
              </TitleH4Component>
              <div className="p-2 p-md-2 p-lg-4 d-md-flex d-lg-block align-items-center justify-content-between">
                <div
                  className="d-flex justify-content-start align-items-center mb-3 mb-md-4"
                  id="linea-llamanos"
                >
                  <div className={StyleGeneral.iconCircle}>
                    <Icon icon="ph:phone" className="color-naranja" />
                  </div>
                  <div className="p-2  p-md-3 p-lg-2 ps-3 ps-md-3  ps-lg-4">
                    <TitleH6Component styleGroup="roboto text-start color-gris">
                      {getTextoByKey("titulo-llamanos")?.valor}
                    </TitleH6Component>
                    {llamanos?.map((item, index) => (
                      <Fragment key={index}>
                        <ParagraphComponent styleGroup="mb-0 color-gris d-none d-xl-block">
                          <small>{getTranslation(item, "titulo")} </small>
                        </ParagraphComponent>
                        <ParagraphComponent styleGroup="mb-0 color-gris d-xl-none">
                          <a
                            href={getTranslation(item, "link") ?? ""}
                            className="color-gris"
                            title={"Llámanos"}
                            aria-label="Llámanos"
                          >
                            <small>{getTranslation(item, "titulo")} </small>
                          </a>
                        </ParagraphComponent>
                      </Fragment>
                    ))}
                  </div>
                </div>
                {escribenos?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-start align-items-center mb-3 mb-md-4"
                    id="linea-escribenos"
                  >
                    <div className={StyleGeneral.iconCircle}>
                      <a
                        href={getTranslation(item, "link")}
                        target="_blank"
                        className="d-inline-flex link-banner"
                        aria-label="Contact link"
                        title="Contact link"
                      >
                        <Icon
                          icon="ic:round-whatsapp"
                          className="color-naranja"
                        />
                      </a>
                    </div>
                    <div className="p-2 ps-3 p-md-3 ps-md-3  p-lg-2 ps-lg-4">
                      <TitleH6Component styleGroup="roboto text-start color-gris">
                        {getTextoByKey("titulo-escribenos")?.valor}
                      </TitleH6Component>

                      <ParagraphComponent
                        key={index}
                        styleGroup="mb-0 color-gris"
                      >
                        <small>{getTranslation(item, "titulo")}</small>
                      </ParagraphComponent>
                    </div>
                  </div>
                ))}
                {email?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-start align-items-center mb-3 mb-md-4"
                    id="linea-email"
                  >
                    <div className={StyleGeneral.iconCircle}>
                      <a
                        href={getTranslation(item, "link")}
                        target="_blank"
                        className="d-inline-flex link-banner"
                        aria-label="Email link"
                        title="Email link"
                      >
                        {" "}
                        <Icon
                          icon="ion:mail-outline"
                          className="color-naranja"
                        />
                      </a>
                    </div>
                    <div className="p-2 ps-3 p-md-3 ps-md-3  p-lg-2 ps-lg-4">
                      <TitleH6Component styleGroup="roboto text-start color-gris">
                        {getTextoByKey("titulo-envianos-email")?.valor}
                      </TitleH6Component>
                      <ParagraphComponent
                        key={index}
                        styleGroup="mb-0 color-gris"
                      >
                        <small>{getTranslation(item, "titulo")}</small>
                      </ParagraphComponent>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={`col-12 col-lg-6  col-xxl-7 offset-lg-1 d-flex align-items-center ${Style.bgS}`}
          >
            <div className="d-md-flex justify-content-center align-items-center position-relative">
              <div className={Style.videoContent}>
                <div
                  className={`${Style.wpPhone} ${StyleGeneral.containerwpPhone}`}
                >
                  <Picture
                    src={url_image + videoimagen?.image}
                    alt="Prodesa"
                    styleGroup={`w-100 ${Style.casePhone}`}
                    height="450"
                    width="250"
                  />
                  <div className={Style.player}>
                    <ReactPlayer
                      url={url_image + video?.video}
                      playing={playing}
                      muted={false}
                      loop={false}
                      width="100%"
                      height="100%"
                      className="reactplayer-class"
                      onPlay={handleOnPlay}
                    />
                  </div>
                  <div className={Style.control}>
                    <div
                      className={`${StyleGeneral.iconCircle} ${
                        playing ? StyleGeneral.play : ""
                      }`}
                      onClick={handlePlay}
                    >
                      <Icon
                        icon={
                          playing ? "ic:round-pause" : "ic:round-play-arrow"
                        }
                        className="color-naranja"
                      />
                    </div>
                  </div>
                </div>
                <Picture
                  src={url_image + phone?.image}
                  alt="Prodesa"
                  styleGroup={`w-100 ${Style.dummyPhone}`}
                  height="450"
                  width="250"
                />
              </div>
              <div className="px-4">
                <TitleH6Component styleGroup="color-white">
                  {getTextoByKey("titulo-descarga-app")?.titulo}{" "}
                </TitleH6Component>
                <ParagraphComponent styleGroup="color-white">
                  {getTextoByKey("titulo-descarga-app")?.valor}
                </ParagraphComponent>
                <div
                  className="d-flex justify-content-center justify-content-lg-start align-items-center"
                  style={{
                    flex: 1,
                    height: "100%",
                  }}
                >
                  <a
                    style={{ width: "50%" }}
                    href={getTranslation(logoApple, "link")}
                    target="_blank"
                    className="d-flex justify-content-center align-items-center p-2"
                  >
                    <Picture
                      src={url_image + logoApple?.image}
                      alt="Prodesa"
                      styleGroup="w-100"
                      height="75"
                      width="220"
                    />
                  </a>
                  <a
                    style={{ width: "50%" }}
                    href={getTranslation(logoAndroid, "link")}
                    target="_blank"
                    className="d-flex justify-content-center align-items-center p-2"
                  >
                    <Picture
                      src={url_image + logoAndroid?.image}
                      alt="Prodesa"
                      styleGroup="w-100"
                      height="75"
                      width="220"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
